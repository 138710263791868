import React from 'react'
import { createTheme } from '@mui/material'
import { typographyTokens } from './typographyTokens'
import { colorTokens } from './colorTokens'
import { AlertExclamationMarkIcon } from 'components/Icons/AlertExclamationMarkIcon'
import { SimpleCheckMark } from 'components/Icons/SimpleCheckMark'

export const muiTheme = createTheme({
  spacing: 4,
  palette: colorTokens as any,
  typography: typographyTokens as any,
  components: {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <AlertExclamationMarkIcon />,
          success: <SimpleCheckMark />,
        },
      },
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          padding: 12,
          borderRadius: '8px',
        },
        /**
         * TODO: Support different text colors for different alert variants
         */
        standardSuccess: {
          backgroundColor: colorTokens.popGreen200,
        },
        standardError: {
          backgroundColor: colorTokens.red100,
        },
        message: {
          ...typographyTokens.bodySMediumSansSerif,
          padding: 0,
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
          'a:last-child > .MuiListItemButton-root': {
            marginBottom: 0,
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
        },
      },
    },
  },
})
