import { css } from 'styled-components'

export const SubtitleStyles = css`
  font-size: 12px;
  line-height: 14px;
`
export const fontFamily = {
  sansSerif: 'Greycliff CF',
  serif: 'Quincy CF',
}
