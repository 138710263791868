import React from 'react'
export const SimpleCheckMark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.3776 4.94785L9.15297 14.7492C8.79797 15.1722 8.16724 15.2274 7.74417 14.8725L3.2262 11.0817"
        stroke="#173E29"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
