/**
 * Shades tokens were taken from Foundations figma:
 * https://www.figma.com/file/tGm3jrL1eSJS30Svw7skc1/Foundations-Library?node-id=507%3A556&mode=dev
 *
 * All other color tokens were taken from Design System: App figma:
 * https://www.figma.com/file/gQRajXJvtFBnOwY0TTJiaJ/Design-System%3A-App?node-id=226%3A1474&mode=dev
 */

export type ColorTokens = typeof colorTokens

export const colorTokens = {
  transparent: 'transparent',

  // SHADES
  shadesBlack: '#000000',
  shades600: '#0E0E0F',
  shades500: '#282C2B',
  shades400: '#5B6362',
  shades300: '#A2A9A9',
  shades250: '#D7D7D7',
  shades200: '#EFF1EC',
  shades100: '#FBFCFA',
  shades00: '#FFFFFF',

  // BLUES
  baseBlue400: '#2960E3',
  baseBlue300: '#4D7EC3',
  baseBlue100: '#D7E1FA',

  // ORANGES
  popOrange400: '#F97648',
  popOrange300: '#FBBA8C',

  // REDS
  baseRed100: '#D95C3F',
  baseRed400: '#D95C3F',

  // YELLOWS
  baseYellow50: '#FDF8F1',
  baseYellow400: '#FBD979',
  popYellow400: '#F0D64B',
  baseYellow500: '#3D260B',

  // PURPLES
  basePurple400: '#4B3B92',

  // GREENS
  popGreen300: '#D0F2E0',
  baseGreen400: '#193231',
  accentGreen400: '#DAFF9D',
  popGreen400: '#66DA9C',
  accentHighlight500: '#147D57',
  popGreen500: '#173E29',
  racingGreen: '#0F1D11',

  // APP
  primaryExpertBlack: '#021E06',
  primaryCommunityGreen: '#1C3D20',
  primarySupportiveSage: '#5F7162',
  primaryDustyMint: '#C0C6BF',
  primaryAdvocateIvory: '#F6F1EE',
  secondaryPeach: '#FBBA8C',
  secondaryYellow: '#F9D9A8',
  secondaryMelon: '#E7EBCC',
  secondaryLavender: '#B3BFF9',
  secondaryRose: '#F1D0F5',
  tertiaryPeach: '#FDD6BA',
  tertiaryYellow: '#FBE4C0',
  tertiaryLavender: '#D8DEFC',
  tertiaryMelon: '#E3E9BB',
  tertiaryRose: '#F5DFF8',

  primary100: '#D8ECE4',
  primary200: '#A8BEB7',
  primary400: '#49615E',
  primary500: '#193231',

  // FOUNDATIONS
  blue50: '#F3F6FD',
  lightGray50: '#1A3331',
  lightGray100: '#EFF0F7',
  greyScaleLabel: '#6E7191',
  neutral100: '#F6F1EE',
  neutral200: '#D5D1CE',
  neutral300: '#93908E',
  neutral400: '#504F4F',
  neutral500: '#0E0E0F',
  red100: '#FADBD4',
  popGreen200: '#E6F7EE',
} as const
