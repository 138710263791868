import React from 'react'
export const AlertExclamationMarkIcon = () => {
  return (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="exclamationmark.triangle">
        <g id="Group">
          <path
            id="Vector"
            d="M2.80553 16.2068H15.7452C17.2059 16.2068 18.1208 15.1573 18.1208 13.8419C18.1208 13.4365 18.0051 13.0143 17.7883 12.6324L11.3093 1.33654C10.8545 0.541671 10.0778 0.143066 9.27611 0.143066C8.4729 0.143066 7.6849 0.544684 7.23991 1.33654L0.760944 12.6339C0.531927 13.0211 0.428467 13.4365 0.428467 13.8419C0.428467 15.1573 1.34337 16.2068 2.80553 16.2068ZM2.94799 14.6304C2.42081 14.6304 2.1024 14.2221 2.1024 13.7598C2.1024 13.6188 2.13135 13.436 2.21289 13.2783L8.5494 2.20472C8.70561 1.92665 8.99654 1.80779 9.27611 1.80779C9.55418 1.80779 9.83525 1.92815 9.99297 2.20622L16.331 13.2896C16.4125 13.4459 16.4483 13.6203 16.4483 13.7598C16.4483 14.2221 16.1162 14.6304 15.5959 14.6304H2.94799Z"
            fill="#0E0E0F"
          />
          <path
            id="Vector_2"
            d="M9.27749 10.4205C9.73049 10.4205 9.99506 10.1605 10.0094 9.677L10.1326 5.61227C10.1486 5.12661 9.77756 4.77539 9.26913 4.77539C8.75384 4.77539 8.39656 5.11975 8.41249 5.60541L8.52885 9.68C8.54327 10.1536 8.80927 10.4205 9.27749 10.4205ZM9.27749 13.1936C9.8142 13.1936 10.2568 12.8026 10.2568 12.2749C10.2568 11.7419 9.82106 11.3561 9.27749 11.3561C8.73392 11.3561 8.29663 11.7472 8.29663 12.2749C8.29663 12.7957 8.74077 13.1936 9.27749 13.1936Z"
            fill="#0E0E0F"
          />
        </g>
      </g>
    </svg>
  )
}
