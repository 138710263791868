import { colorTokens } from './colorTokens'
import { fontFamily } from './fonts'

export const HEADER_LINE_HEIGHT_MULTIPLIER = 1.16
export const BODY_LINE_HEIGHT_MULTIPLIER = 1.44

export type TypographyTokens = typeof typographyTokens

export const typographyTokens = {
  // HEADER
  headerXXLRegularSerif: {
    fontFamily: fontFamily.serif,
    fontSize: 56,
    lineHeight: `${56 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  headerXXLItalicSerif: {
    fontFamily: fontFamily.serif,
    fontStyle: 'italic',
    fontSize: 56,
    lineHeight: `${56 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  headerXLRegularSerif: {
    fontFamily: fontFamily.serif,
    fontSize: 44,
    lineHeight: `${44 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  headerXLItalicSerif: {
    fontFamily: fontFamily.serif,
    fontStyle: 'italic',
    fontSize: 44,
    lineHeight: `${44 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  headerLRegularSerif: {
    fontFamily: fontFamily.serif,
    fontSize: 36,
    lineHeight: `${36 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  headerLItalicSerif: {
    fontFamily: fontFamily.serif,
    fontStyle: 'italic',
    fontSize: 36,
    lineHeight: `${36 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  headerMRegularSerif: {
    fontFamily: fontFamily.serif,
    fontSize: 32,
    lineHeight: `${32 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  headerMItalicSerif: {
    fontFamily: fontFamily.serif,
    fontStyle: 'italic',
    fontSize: 32,
    lineHeight: `${32 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  headerXMRegularSerif: {
    fontFamily: fontFamily.serif,
    fontSize: 28,
    lineHeight: `${28 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  headerXMItalicSerif: {
    fontFamily: fontFamily.serif,
    fontStyle: 'italic',
    fontSize: 28,
    lineHeight: `${28 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  headerSRegularSerif: {
    fontFamily: fontFamily.serif,
    fontSize: 24,
    lineHeight: `${24 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  headerMRegularSansSerif: {
    color: colorTokens.shades600,
    fontFamily: fontFamily.sansSerif,
    fontSize: 32,
    lineHeight: `${32 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
  },
  headerSRegularSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 24,
    lineHeight: `${24 * HEADER_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },

  // BODY
  bodyXLRegularSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 24,
    lineHeight: `${24 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
    fontWeight: 500,
  },
  bodyXLBoldSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 24,
    lineHeight: `${24 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  bodyXLRegularSerif: {
    fontFamily: fontFamily.serif,
    fontSize: 24,
    lineHeight: `${24 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  bodyLRegularSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 20,
    lineHeight: `${20 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  bodyLRegularSerif: {
    fontFamily: fontFamily.serif,
    fontSize: 20,
    lineHeight: `${20 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  bodyMRegularSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 18,
    lineHeight: `${18 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  bodyMMediumSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 18,
    lineHeight: `${18 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
    fontWeight: 600,
  },
  bodyMBoldSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 18,
    lineHeight: `${18 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  bodyMRegularSerif: {
    fontFamily: fontFamily.serif,
    fontSize: 18,
    lineHeight: `${18 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  bodyXMRegularSerif: {
    fontFamily: fontFamily.serif,
    fontSize: 16,
    lineHeight: `${16 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  bodyXMRegularSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 16,
    lineHeight: `${16 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  bodyXMMediumSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 16,
    lineHeight: `${16 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
    fontWeight: 600,
  },
  bodySRegularSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 14,
    lineHeight: `${14 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  bodySMediumSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 14,
    lineHeight: `${14 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
    fontWeight: 600,
  },
  bodyXSRegularSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 12,
    lineHeight: `${12 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
  },
  bodyXSMediumSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 12,
    lineHeight: `${12 * BODY_LINE_HEIGHT_MULTIPLIER}px`,
    color: colorTokens.shades600,
    fontWeight: 600,
  },

  // EYEBROW
  eyebrowXLRegularSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 20,
    letterSpacing: 3.2,
    color: colorTokens.shades600,
  },
  eyebrowXLRegularSerif: {
    fontFamily: fontFamily.serif,
    fontSize: 20,
    letterSpacing: 3.2,
    color: colorTokens.shades600,
  },
  eyebrowLRegularSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 16,
    lineHeight: `${24.32}px`,
    letterSpacing: 3.2,
    color: colorTokens.shades600,
  },
  eyebrowMRegularSansSerif: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 14,
    lineHeight: `${18.48}px`,
    letterSpacing: 2.56,
    color: colorTokens.shades600,
  },
  eyebrowXMRegularSansSerif: {},
  eyebrowSRegularSansSerif: {},
  eyebrowXSRegularSansSerif: {},

  // UTILITY
  utilityShortMRegularSansSerif: {},
  utilityShortXMRegularSansSerif: {},
  utilityShortSRegularSansSerif: {},
  utilityShortXSRegularSansSerif: {},

  // FOUNDATION TOKENS
  textLarge100: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0.18,
    lineHeight: '24px',
  },
  textLarge200: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0.18,
    lineHeight: '24px',
  },
  textLarge300: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0.18,
    lineHeight: '24px',
  },
  textLargest300: {
    fonFamily: fontFamily.sansSerif,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '32px',
  },
  textMedium100: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.16,
    lineHeight: '22px',
  },
  textMedium300: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0.16,
    lineHeight: '22px',
  },
  textSmall100: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.14,
    lineHeight: '20px',
  },
  textSmall200: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.14,
    lineHeight: '20px',
  },
  textSmall300: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.14,
    lineHeight: '20px',
  },
  textTiny100: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.24,
    lineHeight: '16px',
  },
  textTiny200: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.24,
    lineHeight: '16px',
  },
  textTiny300: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 0.24,
    lineHeight: '16px',
  },

  // DEPRECATED FOUNDATION TOKENS
  textMedium: {
    fontFamily: fontFamily.sansSerif,
    color: colorTokens.shades600,
  },
  textLight: {
    fontFamily: fontFamily.sansSerif,
    color: colorTokens.shades600,
  },
}
